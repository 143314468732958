@font-face {
    font-family: 'TTNormsPro-Bold';
    src: url('./assets/fonts/TT_Norms_Pro_Bold.woff2') format("woff2"), url('./assets/fonts/TT_Norms_Pro_Bold.woff') format("woff"), url('assets/fonts/TT_Norms_Pro_Bold.otf') format("opentype"), url('assets/fonts/TT_Norms_Pro_Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro-DemiBold';
    src: url('./assets/fonts/TT_Norms_Pro_DemiBold.woff2') format("woff2"), url('./assets/fonts/TT_Norms_Pro_DemiBold.woff') format("woff"), url('assets/fonts/TT_Norms_Pro_DemiBold.otf') format("opentype"), url('assets/fonts/TT_Norms_Pro_DemiBold.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro-Medium';
    src: url('./assets/fonts/TT_Norms_Pro_Medium.woff2') format("woff2"), url('./assets/fonts/TT_Norms_Pro_Medium.woff') format("woff"), url('assets/fonts/TT_Norms_Pro_Medium.otf') format("opentype"), url('assets/fonts/TT_Norms_Pro_Medium.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro-Regular';
    src: url('./assets/fonts/TT_Norms_Pro_Regular.woff2') format("woff2"), url('./assets/fonts/TT_Norms_Pro_Regular.woff') format("woff"), url('assets/fonts/TT_Norms_Pro_Regular.otf') format("opentype"), url('assets/fonts/TT_Norms_Pro_Regular.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro-Normal';
    src: url('./assets/fonts/TT_Norms_Pro_Normal.woff2') format("woff2"), url('./assets/fonts/TT_Norms_Pro_Normal.woff') format("woff"), url('assets/fonts/TT_Norms_Pro_Normal.otf') format("opentype"), url('assets/fonts/TT_Norms_Pro_Normal.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}
